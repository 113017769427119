export const TuitionReimbursementStatuses = {
  PROOF_SUBMITTED: 'proof_submitted',
  PROOF_APPROVED: 'proof_approved',
  PROOF_REJECTED: 'proof_rejected',
  COURSE_REQUESTED: 'course_requested',
  COURSE_APPROVED: 'course_approved',
  COURSE_REJECTED: 'course_rejected',
  // TODO: TR v2 statuses, remove once V3 is launched
  REIMBURSEMENT_REQUESTED: 'reimbursement_requested',
  REIMBURSEMENT_APPROVED: 'reimbursement_approved',
  REIMBURSEMENT_DENIED: 'reimbursement_denied',
};

export const TuitionReimbursementServiceTypes = {
  ASSISTANCE: 'assistance',
  REIMBURSEMENT: 'reimbursement',
};

export const TuitionReimbursementWizardSteps = {
  COURSE_REQUEST: 'courseRequest',
  PROOF_OF_COMPLETION: 'proofOfCompletion',
};

export const EducationAssistanceLabels = {
  [TuitionReimbursementServiceTypes.ASSISTANCE]: 'Tuition Assistance',
  [TuitionReimbursementServiceTypes.REIMBURSEMENT]: 'Tuition Reimbursement',
  generic: 'Education Assistance',
};

/**
 * Eligibility Policies are saved to the `/public/policies` directory
 * File names are saved as a SHA1 hash of the file content, which is saved to
 * the database upon submission of a course request
 * Only the latest policy for a client is stored in the `/latest` directory
 * Older policies will live in a `/past` directory
 */
export const eligibilityPolicyClientFileHash = {
  default: '67269afd7962546a33580d89e304d022a9b416c4',
  assistanceDemo: '67269afd7962546a33580d89e304d022a9b416c4',
  winter: '67269afd7962546a33580d89e304d022a9b416c4',
  cityofalexandria: 'eea10ae70e5de9aafd4d155e1a0d9cf522985b85',
};
