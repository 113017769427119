import { get } from 'lodash';
import { createSelector } from 'reselect';

const getFlags = state => state.flags;

export const getIsMultiToolWelcomeEnabled = createSelector(
  getFlags,
  flags => flags.showMultiToolWelcomePage === true,
);

export const getIsCollegeSavingsPlannerToolEnabled = createSelector(
  getFlags,
  flags => flags.showCollegeSavingsPlannerTool === true,
);

export const getIsCSPCostCalculatorV2Enabled = createSelector(
  getFlags,
  flags => flags.showCSPCostCalculatorV2 === true,
);

export const getIsCSP529MarketplaceEnabled = createSelector(
  getFlags,
  flags => flags.showCSP529Marketplace === true,
);

export const getIsCSPScholarshipDatabaseEnabled = createSelector(
  getFlags,
  flags => flags.showCSPScholarshipDatabase === true,
);

export const getIsNewCSPStartPageEnabled = createSelector(
  getIsCSPCostCalculatorV2Enabled,
  getIsCSP529MarketplaceEnabled,
  getIsCSPScholarshipDatabaseEnabled,
  (
    showCSPCostCalculatorV2,
    showCSP529Marketplace,
    showCSPScholarshipDatabase,
  ) =>
    showCSPCostCalculatorV2 ||
    showCSP529Marketplace ||
    showCSPScholarshipDatabase,
);

export const getIsRetirementMatchDemo = createSelector(
  getFlags,
  flags => flags.showRetirementMatchDemo === true,
);

export const getIsRetirementMatchV1Enabled = createSelector(
  getFlags,
  flags => flags.showRetirementMatchV1 === true,
);

export const getIsHideRetirementMatchSideBarEnabled = createSelector(
  getFlags,
  flags => flags.hideRetirementMatchSidebar === true,
);

export const getPaywallPrice = createSelector(
  getFlags,
  flags => flags.paywallPrice,
);

export const getIsTuitionReimbursementEnabled = createSelector(
  getFlags,
  flags => flags.showTuitionReimbursement === true,
);

export const getIsEmergencySavingsDemoEnabled = createSelector(
  getFlags,
  flags => flags.showEmergencySavingsDemo === true,
);

export const getIsEmergencySavingsV1Enabled = createSelector(
  getFlags,
  flags => flags.showEmergencySavingsV1 === true,
);

export const getIsKitchenSinkEnabled = createSelector(
  getFlags,
  flags => flags.showKitchenSink === true,
);

export const getIsContributionsEnabled = state => state.flags.showSLC;

export const showIDRWaiverExpiration = createSelector(
  getFlags,
  flags => flags.showIDRWaiverExpiration === true,
);

export const getIsSummerBasicEnabled = createSelector(getFlags, flags =>
  get(flags, 'showSummerBasic', false),
);

export const getGlobalAlert = createSelector(getFlags, flags =>
  get(flags, 'globalAlertBanner', { show: false }),
);

export const getIsV2SLCEnabled = createSelector(getFlags, flags =>
  get(flags, 'showV2SLC', false),
);

export const getIsSaveEnabled = state => state.flags.showSummerSave;
