import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { COLORS, Text } from '@summer/ui-components';
import {
  topPadding,
  bottomPadding,
  horizontalPadding,
} from '@summer/ui-components/src/core/ModalPopup';

import OpenProofWizardButton from 'components/pages/tuitionReimbursement/dashboard/shared/OpenProofWizardButton';
import TuitionReimbursementStatus from 'components/pages/tuitionReimbursement/dashboard/shared/TuitionReimbursementStatus';
import ModalPopup from 'components/shared/ModalPopup';
import { Link } from 'components/shared/typography';
import { DesktopHDSize, MobileSize } from 'constants/styleguide';
import { EDU_ASSIST_DETAILS_MODAL_VIEWED } from 'constants/trackingEvents';
import {
  TuitionReimbursementServiceTypes,
  TuitionReimbursementStatuses,
} from 'constants/tuitionReimbursement';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { fetchFileDownloadLink } from 'redux/actions/fileCollections.actions';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';
import { formatDateRange } from 'utils/date';
import { dollars } from 'utils/numbers';
import { getEducationAssistanceLabel } from 'utils/tuitionReimbursement';

const dateFormat = 'MMM. dd, yyyy';

const courseDetails = [
  {
    displayName: 'Course Provider',
    key: 'courseProvider',
    getValue: course => course.courseProvider,
  },
  {
    displayName: 'Course Name',
    key: 'courseName',
    getValue: course => course.courseName,
  },
  {
    displayName: 'Education Category',
    key: 'educationCategory',
    getValue: course => course.category ?? 'N/A',
  },
  {
    displayName: 'Start & End Date',
    key: 'courseDates',
    getValue: course => {
      return `${formatDateRange(
        course.courseStartDate,
        course.courseEndDate,
        dateFormat,
      )}`;
    },
  },
  {
    displayName: 'Course Cost',
    key: 'courseCost',
    getValue: course => dollars(course.courseCost, { precision: 2 }),
  },
  {
    displayName: 'Date Requested',
    key: 'createdAt',
    getValue: course => format(parseISO(course.createdAt), dateFormat),
  },
];

const RenderAttachments = ({ collection }) => {
  const dispatch = useDispatch();

  const getFileDownloadLink = fileId => {
    dispatch(
      fetchFileDownloadLink(
        { fileId },
        {
          onSuccess: responseLink => {
            window.open(responseLink, '_blank');
          },
        },
      ),
    );
  };

  return collection.map(attachment => {
    return (
      <Link
        key={attachment.name}
        onClick={() => getFileDownloadLink(attachment.id)}
        underline
      >
        {attachment.name}
      </Link>
    );
  });
};

const cardDescriptionShared = {
  proof_rejected: {
    title: 'Action required!',
    description: `Your proof of payment and completion has been rejected because:`,
    reasonKey: 'rejectionReason',
  },
};

const assistanceCardDescription = {
  ...cardDescriptionShared,
  course_requested: {
    title: 'Waiting for Approval',
    description:
      'Your course request has been submitted. Once approved, you will receive tuition assistance from your employer so you can proceed with your course.',
  },
  course_rejected: {
    title: 'Course Rejected!',
    description:
      'You have not been approved to receive Tuition Assistance for this course because:',
    reasonKey: 'rejectionReason',
    subDescription:
      'To start a new request, close this window and click \u201CCreate New Request\u201D to submit new documentation or a new course for approval.',
  },
  course_approved: {
    title: 'Course approved!',
    description:
      'Your course has been approved! Your employer will provide tuition assistance so you may proceed with your course.',
  },
  proof_submitted: {
    title: 'Proof of Completion Submitted!',
    description:
      'If approved, your request is complete and no further action is required.',
  },
};

const reimbursementCardDescription = {
  ...cardDescriptionShared,
  course_requested: {
    title: 'Waiting for Approval',
    description:
      'Your request to study has been filed and a response should be expected soon. Once approved, you can go ahead to start your study.',
  },
  course_rejected: {
    title: 'Course Rejected!',
    description:
      'Your course has been rejected for future reimbursement because:',
    reasonKey: 'rejectionReason',
  },
  course_approved: {
    title: 'Course approved!',
    description:
      'Your course has been approved for future reimbursement once completed. Happy studying!',
  },
  proof_submitted: {
    title: 'Proof of Completion Submitted!',
    description:
      'If everything looks good, your employer will reimburse you in your paycheck.',
  },
  proof_approved: {
    title: 'Reimbursement Complete!',
    description:
      'Your employer will reimburse you shortly if they have not already done so.',
  },
};

const TuitionReimbursementDetailsModal = ({ isOpen, togglePopup, course }) => {
  const dispatch = useDispatch();
  const serviceType = useSelector(getTuitionReimbursementServiceType);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        trackServerSide(EDU_ASSIST_DETAILS_MODAL_VIEWED, {
          integrations: { All: true },
        }),
      );
    }
  }, [dispatch, isOpen]);

  if (!course) {
    return null;
  }

  const {
    tuitionReimbursementCompletionProof: completionProofFiles,
    tuitionReimbursementPaymentProof: paymentProofFiles,
    tuitionReimbursementAttachment: attachmentFiles,
  } = course.attachments;

  const showProofFiles =
    completionProofFiles.length > 0 && paymentProofFiles.length > 0;

  const descriptionOptions =
    serviceType === TuitionReimbursementServiceTypes.REIMBURSEMENT
      ? reimbursementCardDescription
      : assistanceCardDescription;

  const cardDescription = descriptionOptions[course.status];

  return (
    <ModalPopup
      isOpen={isOpen}
      togglePopup={togglePopup}
      width={800}
      bodyPadding={`${topPadding} 0px 0px 0px`}
      component={() => {
        return (
          <Container>
            <StyledHeader>
              {getEducationAssistanceLabel(serviceType).toUpperCase()}
            </StyledHeader>
            <CourseNameHeader>{course.courseName}</CourseNameHeader>
            <BadgeContainer>
              <TuitionReimbursementStatus
                status={course.status}
                createdAt={course.createdAt}
              />
            </BadgeContainer>
            {cardDescription && (
              <TuitionReimbursementCardDiv
                course={course}
                cardDescription={cardDescription}
              />
            )}
            <BodyContainer>
              <CoursesContainer>
                {courseDetails.map(detail => (
                  <CourseSectionDiv
                    key={detail.key}
                    displayName={detail.displayName}
                    getValue={() => detail.getValue(course)}
                  />
                ))}
                <CourseSection>
                  <DisplaySection>
                    <DisplayName>Attachments</DisplayName>
                  </DisplaySection>
                  <RenderAttachments collection={attachmentFiles} />
                </CourseSection>
              </CoursesContainer>
              <AdditionalInformation>
                <DisplayName>Additional Information</DisplayName>
                <DisplaySection>
                  {course.additionalInformation || 'N/A'}
                </DisplaySection>
              </AdditionalInformation>
              {showProofFiles && (
                <CoursesContainer>
                  <CourseSection>
                    <DisplaySection>
                      <DisplayName>Proof of Payment</DisplayName>
                    </DisplaySection>
                    <RenderAttachments collection={paymentProofFiles} />
                  </CourseSection>
                  <CourseSection>
                    <DisplaySection>
                      <DisplayName>Proof of Completion</DisplayName>
                    </DisplaySection>
                    <RenderAttachments collection={completionProofFiles} />
                  </CourseSection>
                </CoursesContainer>
              )}
            </BodyContainer>
          </Container>
        );
      }}
    />
  );
};

const TuitionReimbursementCardDiv = ({ cardDescription, course }) => {
  const showProofWizardButton =
    course.status === TuitionReimbursementStatuses.COURSE_APPROVED ||
    course.status === TuitionReimbursementStatuses.PROOF_REJECTED;

  return (
    <TuitionReimbursementCard>
      <CardContentContainer>
        <Title weight="bold">{cardDescription.title}</Title>
        <DisplaySection size="small">
          {cardDescription.description}
        </DisplaySection>
        {cardDescription.reasonKey && (
          <>
            <br />
            <DisplaySection>
              <StyledList>
                <li>
                  <RejectionReason size="small">
                    {course[cardDescription.reasonKey] || 'N/A'}
                  </RejectionReason>
                </li>
              </StyledList>
            </DisplaySection>
          </>
        )}
        {cardDescription.subDescription && (
          <DisplaySection size="small">
            <br />
            {cardDescription.subDescription}
          </DisplaySection>
        )}
      </CardContentContainer>
      {showProofWizardButton && (
        <WizardButtonContainer>
          <OpenProofWizardButton
            uuid={course.uuid}
            status={course.status}
            attachments={course.attachments}
          />
        </WizardButtonContainer>
      )}
    </TuitionReimbursementCard>
  );
};

const CourseSectionDiv = ({ displayName, getValue }) => {
  return (
    <CourseSection>
      <DisplaySection>
        <DisplayName>{displayName}</DisplayName>
      </DisplaySection>
      <DisplaySection>{getValue()}</DisplaySection>
    </CourseSection>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: ${DesktopHDSize};
  @media (max-width: ${MobileSize}) {
    overflow: scroll;
    flex-direction: column;
    padding: 0;
  }
`;

const RejectionReason = styled(Text)`
  color: ${COLORS.orange};
`;

const BodyContainer = styled.div`
  width: 100%;
  background-color: #f9fafc;
  padding: 12px ${horizontalPadding} ${bottomPadding} ${horizontalPadding};
`;

const TuitionReimbursementCard = styled.div`
  display: flex;
  background-color: #edf3f7;
  margin: 0px 40px 24px 40px;
  padding: 20px 24px;
  font-size: 14px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const CardContentContainer = styled.div`
  text-align: left;
  flex-direction: column;
  align-items: left;
  padding: 6px;

  order: 1;

  @media (max-width: ${MobileSize}) {
    order: 2;
  }
`;

const StyledList = styled.ul`
  margin-left: 20px;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 6px 0px 6px 0px;
`;

const BadgeContainer = styled.div`
  padding-bottom: 12px;
  width: fit-content;
  align-self: center;
`;

const CourseNameHeader = styled.div`
  padding: 12px ${horizontalPadding};
  font-weight: bold;
  font-size: 32px;

  text-wrap: balance;
  overflow-wrap: anywhere;
`;

const StyledHeader = styled.div`
  color: ${COLORS.medGrey};
  font-weight: bold;
  font-size: 20px;
`;

const CoursesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AdditionalInformation = styled.div`
  text-align: left;
`;

const WizardButtonContainer = styled.div`
  margin-left: auto;
  margin-top: 6px;
  align-self: flex-start;

  order: 2;

  @media (max-width: ${MobileSize}) {
    order: 1;
    width: 100%;
  }
`;

const DisplayName = styled(Text)`
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const DisplaySection = styled(Text)`
  display: flex;
  flex-direction: row;

  text-wrap: pretty;
  overflow-wrap: anywhere;
`;

const CourseSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: left;
  padding: 24px 0px;
`;

export default TuitionReimbursementDetailsModal;
