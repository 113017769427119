import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Alert, Button, Header, Strong, Text } from '@summer/ui-components';

import { OnboardingContainer } from 'components/pages/onboarding/shared';
import { Link } from 'components/shared/typography';
import { welcome, verify } from 'constants/onboardingSteps';
import { checkEmailIsVerified, verifyUser } from 'redux/actions/auth.actions';
import {
  persistOnboardingStep,
  setOnboardingStep,
} from 'redux/actions/onboard.actions';
import { sendAccountVerificationEmail } from 'redux/actions/user.actions';
import { getOnboardingCurrentStep } from 'redux/selectors/onboarding.selectors';
import { getUserFirstName } from 'redux/selectors/profile.selectors';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import {
  getUserEmail,
  getUserIsVerified,
} from 'redux/selectors/user.selectors';

import SecurityFooter from './shared/SecurityFooter';
import SecurityHeader from './shared/SecurityHeader';

const Verify = ({
  currentStep,
  email,
  firstName,
  isVerified,
  isLoading,
  verificationToken,
  isResendLoading,
  error,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [checkedVerification, setCheckedVerification] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(
    function verifyOnMount() {
      if (verificationToken) {
        dispatch(verifyUser({ token: verificationToken }));
      }
    },
    [dispatch, verificationToken],
  );

  useEffect(() => {
    /**
     * The component can mount with the user already verified. Such a user will
     * will be redirected right away. If not already verified, the user will
     * be redirected after check user updates the user in redux
     */
    if (isVerified) {
      push(welcome);
      if (currentStep === verify) {
        dispatch(setOnboardingStep(welcome));
        dispatch(persistOnboardingStep(welcome));
      }
    }
  }, [isVerified, push, currentStep, dispatch]);

  const checkVerificationStatus = () => {
    dispatch(checkEmailIsVerified());
    setCheckedVerification(true);
    setEmailSent(false);
  };

  const resendEmail = () => {
    dispatch(sendAccountVerificationEmail({ email }));
    setEmailSent(true);
    setCheckedVerification(false);
  };

  const isStillUnverified = !isVerified && checkedVerification && !isLoading;

  return (
    <>
      <OnboardingContainer>
        <SecurityHeader />
        <HeaderContainer>
          <Header h1 center>
            Welcome, {firstName}! Verify your account to get started
          </Header>
        </HeaderContainer>
        <TextContainer>
          <Text large center paragraph>
            You&rsquo;re almost there! We sent you an email.{' '}
            <Strong>
              Check for the latest email and click on the link to verify.
            </Strong>
          </Text>
        </TextContainer>
        <ResendLink small color="secondary">
          Can&rsquo;t find it?{' '}
          <Link underline inheritColor onClick={resendEmail}>
            Resend verification email.
          </Link>
        </ResendLink>
        <ButtonContainer>
          <Button
            width={190}
            onClick={checkVerificationStatus}
            isLoading={isLoading}
          >
            I&rsquo;ve verified
          </Button>
        </ButtonContainer>

        {isStillUnverified && (
          <Text small color="error">
            We haven&rsquo;t receive your verification yet. Please click the
            link in your most recent email from us to verify.
          </Text>
        )}
        {emailSent && !error && (
          <Alert theme="success" dismissable={false}>
            We just sent over a new email, check for the most recent one!
          </Alert>
        )}
        {!isResendLoading && error && (
          <Alert theme="info" dismissable={false}>
            {error}
          </Alert>
        )}
      </OnboardingContainer>

      <SecurityFooter />
    </>
  );
};

const HeaderContainer = styled.div`
  margin-bottom: 24px;
`;

const TextContainer = styled.div`
  margin-bottom: 16px;
`;

const ResendLink = styled(Text)`
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 24px;
`;

const mapStateToProps = state => ({
  currentStep: getOnboardingCurrentStep(state),
  email: getUserEmail(state),
  firstName: getUserFirstName(state),
  isVerified: getUserIsVerified(state),
  verificationToken: state.currentUser.verificationToken,
  isLoading: isLoading(state, 'checkEmailIsVerified'),
  isResendLoading: isLoading(state, 'sendAccountVerificationEmail'),
  error: getErrorMessage(state, 'sendAccountVerificationEmail'),
});

export default connect(mapStateToProps)(Verify);
