/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { push as redirectTo } from 'connected-react-router';
import { pick } from 'lodash';

import { API_REQUEST } from 'constants/actionTypes';
import {
  networkFailure,
  networkPending,
  networkSuccess,
} from 'redux/actions/api.actions';
import {
  clearServerError,
  finishLoading,
  showServerError,
  startLoading,
} from 'redux/actions/ui.actions';
import { logout } from 'redux/actions/user.actions';
import handleSideEffects from 'redux/handleSideEffects';
import { isPrivateRoute } from 'redux/middleware/navigation.middleware';
import { isLoading } from 'redux/selectors/ui.selectors';
import { getUserEmail } from 'redux/selectors/user.selectors';
import { parsePathForIdentifiers } from 'utils/error';

// list of routes and status codes to ignore from sending to sentry
const exemptStatusCodesAndPaths = {
  404: ['/v1/user/eligibility'],
};

const captureAPIError = errorResponse => {
  const { status, data, statusText, config, request } = errorResponse;
  const errorUrl = new URL(request?.responseURL);
  const { pathname } = errorUrl;

  const fingerprintPath = parsePathForIdentifiers(pathname);
  const method = config?.method ?? '[missing-method]';

  const shouldIgnoreSentry =
    exemptStatusCodesAndPaths[status]?.includes(pathname);

  if (!shouldIgnoreSentry) {
    Sentry.withScope(scope => {
      scope.setFingerprint([fingerprintPath, method, status]);
      scope.setTag('path', pathname);
      scope.setExtra('details', pick(data, ['error', 'message']));
      Sentry.captureMessage(
        `API Error: ${method} ${fingerprintPath} ${status} ${statusText}`,
        { level: Sentry.Severity.Error },
      );
    });
  }
};

const apiMiddleware = handleSideEffects({
  [API_REQUEST]: (dispatch, { payload, meta }, state) => {
    const {
      urlWithoutBase,
      url,
      params,
      method = 'GET',
      headers = {},
      body,
      withCredentials = true,
    } = payload;
    const baseUrl = window.location.origin;

    dispatch(
      networkPending({
        meta: {
          ...meta,
          request: payload,
        },
      }),
    );

    const onSuccess = response => {
      dispatch(
        networkSuccess({
          response: response.data,
          meta: {
            ...meta,
            request: payload,
          },
        }),
      );

      if (meta.label) {
        if (
          meta.poll &&
          meta.continuePolling &&
          meta.continuePolling(response)
        ) {
          return;
        }
        dispatch(finishLoading({ label: meta.label }));
      }
    };

    const onFailure = error => {
      const { fromAction } = meta;
      console.log(`Error from Action:${fromAction}`);

      dispatch(
        networkFailure({
          error,
          meta: {
            ...meta,
            request: payload,
          },
        }),
      );

      if (error.response) {
        if (meta.label) {
          dispatch(
            showServerError({
              label: meta.label,
              error: {
                message: error.response.data?.message,
                status: error.response.status,
              },
            }),
          );
        }
        if (error.response.status === 401) {
          if (getUserEmail(state) !== null) {
            const currentPath = state.router?.location?.pathname;
            if (isPrivateRoute(currentPath)) {
              dispatch(logout({ pathOrigin: currentPath }));
            }
          }
        } else if (
          error.response.status === 403 &&
          error.response.data.message === 'User must complete MFA challenge' &&
          state.router.location.pathname !== '/onboard/verify' &&
          state.router.location.pathname !== '/onboard/creditkarma/oauth' &&
          !isLoading(state, 'createAccount')
        ) {
          dispatch(redirectTo('/mfa/sms', state.router.location?.state));
        } else if (error.response.status === 423) {
          // Redirect to email verification page if account has been locked
          dispatch(redirectTo('/verify'));
        } else if (error.response.status === 429) {
          dispatch(
            showServerError({
              label: meta.label,
              error: {
                message: `There have been too many incorrect login attempts. <b>Please wait 15 minutes before trying again.</b>`,
                status: error.response.status,
              },
            }),
          );
        } else if (
          error.response.status === 502 ||
          error.response.status === 503 ||
          error.response.status === 504
        ) {
          captureAPIError(error.response);
        } else if (
          error.response.status >= 400 &&
          error.response.status !== 401 &&
          error.response.status !== 403
        ) {
          captureAPIError(error.response);
        }
      } else if (error.request) {
        Sentry.configureScope(scope => {
          scope.setExtra('requestConfig', error.config);
          scope.setExtra('baseUrl', baseUrl);
          scope.setExtra('path', error.response?.config?.url);
          Sentry.captureException(error);
        });

        dispatch(redirectTo('/error'));
      } else {
        Sentry.configureScope(scope => {
          scope.setExtra('baseUrl', baseUrl);
          scope.setExtra('path', error.response?.config?.url);
          Sentry.captureException(error);
        });

        dispatch(redirectTo('/error'));
      }

      if (meta.label) {
        // Prevent loading from finishing on logout to avoid jumpy UX
        // Only checkUser will be preserved, all other UI state will be cleared
        if (error.response?.status === 401 && getUserEmail(state) !== null) {
          if (meta.label === 'checkUser') {
            dispatch(finishLoading({ label: meta.label }));
          }
        } else {
          dispatch(finishLoading({ label: meta.label }));
        }
      }
    };

    if (meta.label) {
      dispatch(startLoading({ label: meta.label }));
      dispatch(clearServerError({ label: meta.label }));
    }

    axios
      .request({
        url: urlWithoutBase || process.env.REACT_APP_API_BASE_URL + url,
        params,
        method,
        headers,
        data: body,
        withCredentials,
      })
      .then(onSuccess)
      .catch(onFailure);
  },
});

export default apiMiddleware;
