import { keys, pick } from 'lodash';

import {
  SET_ELIGIBILITY_BENEFITS,
  SET_TUITION_REIMBURSEMENT,
  SET_TUITION_REIMBURSEMENT_PARTNER_CONFIG,
} from 'constants/actionTypes';

const initialState = {
  courses: [],
  partnerConfig: { serviceType: '' },
  benefitDetails: null,
};

const tuitionReimbursementReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case SET_ELIGIBILITY_BENEFITS: {
      return { ...state, benefitDetails: payload.educationalAssistance };
    }

    case SET_TUITION_REIMBURSEMENT: {
      return { ...state, ...pick(payload, keys(initialState)) };
    }

    case SET_TUITION_REIMBURSEMENT_PARTNER_CONFIG: {
      return { ...state, partnerConfig: payload };
    }

    default:
      return state;
  }
};

export default tuitionReimbursementReducer;
