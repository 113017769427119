import React from 'react';
import styled from 'styled-components';

import {
  IconMedallion,
  LoanSync,
  Button,
  Text,
  FlexColumn,
  COLORS,
} from '@summer/ui-components';
import Link from '@summer/ui-components/src/core/typography/Link';

import { TabletSize } from 'constants/styleguide';

const EmptyServicersDisplay = ({ openPlaid, setIsPlaidDone, isPlaidReady }) => {
  const handleSync = () => {
    openPlaid();
    setIsPlaidDone(false);
  };

  return (
    <Container>
      <IconMedallion
        SvgComponent={LoanSync}
        size={64}
        color={COLORS.lightBlue}
      />
      <TextContainer>
        <Text bold large paragraph>
          Loan Sync
        </Text>
        <Text paragraph>
          In order to enable your retirement match benefit, we need to learn
          more about your student loans. If you have private student loans you
          will securely sync your loans with Summer. If you have federal student
          loans, such as those serviced by MOHELA, Aidvantage, Nelnet, or
          EdFinancial please visit the{' '}
          <Link to="/retirement-match/dashboard/documents">Documents</Link> tab
          instead.
        </Text>
      </TextContainer>
      <Button
        type="button"
        kind="open-plaid"
        primary
        width={160}
        onClick={handleSync}
        isLoading={isPlaidReady}
      >
        Sync a loan
      </Button>
    </Container>
  );
};

const Container = styled(FlexColumn)`
  padding: 56px 94px 32px;
  align-items: center;
  text-align: center;

  @media (max-width: ${TabletSize}) {
    padding: 48px 24px;
  }
`;

const TextContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;

  & > * {
    margin-bottom: 4px;
  }
`;

export default EmptyServicersDisplay;
