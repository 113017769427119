import { keys, pick } from 'lodash';

import {
  SET_ELIGIBILITY_BENEFITS,
  SET_RETIREMENT_MATCH,
} from 'constants/actionTypes';

const initialState = {
  consentedAt: null,
  benefitDetails: null,
};

const retirementMatchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ELIGIBILITY_BENEFITS: {
      return { ...state, benefitDetails: payload.retirementMatch };
    }

    case SET_RETIREMENT_MATCH: {
      return { ...state, ...pick(payload, keys(initialState)) };
    }

    default:
      return state;
  }
};

export default retirementMatchReducer;
