import { push as redirectTo } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 } from 'uuid';

import {
  Button,
  COLORS,
  Cross,
  FlexColumn,
  FlexRow,
  GraduationCap,
  Icon,
  Text,
} from '@summer/ui-components/src';

import TuitionReimbursementCompleteModal from 'components/pages/tuitionReimbursement/dashboard/TuitionReimbursementCompleteModal';
import TuitionReimbursementDetailsModal from 'components/pages/tuitionReimbursement/dashboard/TuitionReimbursementDetailsModal';
import OpenProofWizardButton from 'components/pages/tuitionReimbursement/dashboard/shared/OpenProofWizardButton';
import TuitionReimbursementStatus from 'components/pages/tuitionReimbursement/dashboard/shared/TuitionReimbursementStatus';
import TuitionReimbursementLandingPage from 'components/pages/tuitionReimbursement/dashboard/shared/tuitionReimbursementLandingPage';
import Link from 'components/shared/typography/Link';
import { MobileSize, TabletSize } from 'constants/styleguide';
import { TuitionReimbursementStatuses } from 'constants/tuitionReimbursement';
import { setTuitionReimbursementUi } from 'redux/actions/ui.actions';
import { getTuitionReimbursement } from 'redux/selectors/tuitionReimbursement.selectors';
import { getTuitionReimbursementUi } from 'redux/selectors/ui.selectors';

/** @typedef {{ uuid: string; status: string; courseName: string; courseProvider: string; createdAt: string; }} Course */

/**
 * @param {Course} props
 * @returns {React.ReactNode}
 */
const Course = course => {
  const showProofWizardButton =
    course.status === TuitionReimbursementStatuses.COURSE_APPROVED ||
    course.status === TuitionReimbursementStatuses.PROOF_REJECTED;

  return (
    <CourseRowContainer data-testid="tuition-reimbursement-course-row">
      <FlexRow>
        <GradCap
          width={30}
          SvgComponent={GraduationCap}
          stroke={COLORS.medGrey}
        />
        <FlexColumn>
          <CourseName>
            <CourseText bold size="large">
              {course.courseName}
            </CourseText>
          </CourseName>
          <CourseText>{course.courseProvider}</CourseText>
        </FlexColumn>
      </FlexRow>
      <StatusCTAContainer>
        <TuitionReimbursementStatus
          createdAt={course.createdAt}
          status={course.status}
        />
        {showProofWizardButton && (
          <OpenProofWizardButton
            uuid={course.uuid}
            status={course.status}
            attachments={course.attachments}
          />
        )}
      </StatusCTAContainer>
    </CourseRowContainer>
  );
};

const TuitionReimbursementCompletePage = () => {
  const dispatch = useDispatch();
  const tuitionReimbursement = useSelector(getTuitionReimbursement);
  const tuitionReimbursementUi = useSelector(getTuitionReimbursementUi);
  const [courses, setCourses] = useState([]);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [courseForModal, setCourseForModal] = useState(null);

  const openDetailsModal = course => () => {
    setCourseForModal(course);
    setIsDetailsModalOpen(true);
  };

  const closeDetailModal = () => {
    setCourseForModal(null);
    setIsDetailsModalOpen(false);
  };

  const toggleModal = useCallback(() => {
    dispatch(
      setTuitionReimbursementUi({
        showCompleteModal: !tuitionReimbursementUi.showCompleteModal,
      }),
    );
  }, [dispatch, tuitionReimbursementUi]);

  useEffect(() => {
    setCourses(tuitionReimbursement.courses);
  }, [tuitionReimbursement]);

  const handleCreateNewRequest = useCallback(() => {
    dispatch(
      redirectTo('/educational-assistance/course-request', {
        courseId: v4(),
      }),
    );
  }, [dispatch]);

  return (
    <TuitionReimbursementLandingPage>
      <Container>
        <ButtonContainer>
          <Button onClick={handleCreateNewRequest}>
            <ButtonCross fill={COLORS.white} SvgComponent={Cross} /> Create New
            Request
          </Button>
        </ButtonContainer>
        {courses.map(course => (
          <CourseContainer key={course.uuid}>
            <Link onClick={openDetailsModal(course)}>
              <Course {...course} />
            </Link>
          </CourseContainer>
        ))}
        <TuitionReimbursementCompleteModal
          isOpen={tuitionReimbursementUi.showCompleteModal}
          togglePopup={toggleModal}
        />
        <TuitionReimbursementDetailsModal
          course={courseForModal}
          isOpen={isDetailsModalOpen}
          togglePopup={closeDetailModal}
        />
      </Container>
    </TuitionReimbursementLandingPage>
  );
};

const Container = styled.div`
  @media (min-width: ${MobileSize}) {
    padding: 10px;
  }
`;

const ButtonContainer = styled(FlexRow)`
  button {
    height: 48px;
    margin-left: auto;
    margin-right: 0;
    padding: 8px;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  justify-content: flex-end;
`;

const CourseContainer = styled(FlexColumn)`
  margin-top: 16px;
  box-shadow: 0 0 4px 0 ${COLORS.grey};
`;

const CourseRowContainer = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 24px;
  background-color: ${COLORS.white};

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      margin-bottom: 16px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    @media (max-width: ${MobileSize}) {
      padding: 16px 12px 12px 16px;
    }
  }
`;

const StatusCTAContainer = styled(FlexRow)`
  gap: 16px;

  @media (max-width: ${MobileSize}) {
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-top: 8px;
  }
`;

const CourseText = styled(Text)`
  max-width: 360px;

  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${MobileSize}) {
    max-width: 240px;
  }
`;

const GradCap = styled(Icon)`
  margin-right: 16px;
`;

const ButtonCross = styled(Icon)`
  display: inline;
  height: 24px;
  margin-right: 12px;
`;

const CourseName = styled.div`
  margin-bottom: 8px;
`;

export default TuitionReimbursementCompletePage;
