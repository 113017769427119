import { SET_ELIGIBILITY_BENEFITS } from 'constants/actionTypes';

const initialState = {
  benefitDetails: null,
};

const summerSaveReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ELIGIBILITY_BENEFITS: {
      return { ...state, benefitDetails: payload.summerSave };
    }

    default:
      return state;
  }
};

export default summerSaveReducer;
