import React from 'react';
import styled from 'styled-components';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { get, map, findIndex } from 'lodash';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';

import useDimensions from '../../helpers/hooks/useDimensions';

import { Text } from '../typography';
import LoadingBars from '../LoadingBars';

import { COLORS, MobileSize } from '../../helpers/constants/styleguide';
import { hexToRgba } from '../../helpers/utils/common';

const buildUrl = (baseURL, tabURL) => {
  let URL = '';
  if (baseURL) {
    URL = baseURL;
  }
  if (tabURL) {
    URL = `${URL}${tabURL}`;
  }
  return URL;
};

const TabGroup = ({ tabs, baseUrl, history, location, defaultPath }) => {
  const [tabListRef, { height: headerHeight }] = useDimensions();
  const handleTabSelect = tabIndex => {
    if (!tabs[tabIndex].disabled) {
      history.push(buildUrl(baseUrl, tabs[tabIndex].url));
    }
  };

  const getSelectedTabIndex = React.useMemo(() => {
    const { pathname } = location;

    const selectedIndex = findIndex(tabs, tab => {
      return pathname === buildUrl(baseUrl, tab.url);
    });

    if (selectedIndex === -1 && defaultPath) {
      const defaultUrl = buildUrl(baseUrl, defaultPath);
      const index = findIndex(tabs, tab => pathname === defaultUrl);

      history.push(defaultUrl);
      return index;
    }

    if (!get(tabs[selectedIndex], 'disabled', false)) {
      return selectedIndex;
    }

    const rootIndex = findIndex(tabs, tab => {
      return buildUrl(baseUrl, tab.url) === buildUrl(baseUrl);
    });

    history.push(buildUrl(baseUrl));

    return rootIndex;
  }, [location, tabs, baseUrl, history, defaultPath]);

  return (
    <TabsContainer
      selectedIndex={getSelectedTabIndex}
      onSelect={handleTabSelect}
    >
      <TabListBackgroundWrapper>
        <TabListContainer ref={tabListRef}>
          <StyledTabList id="styled-tab-list">
            {map(tabs, ({ title, disabled = false }) => (
              <StyledTab
                key={title}
                className={tabs.length === 1 ? '' : 'multi-tab'}
                disabled={disabled}
                data-testid={`tab-${title}`}
              >
                <Text color="secondary" bold>
                  {title}
                </Text>
              </StyledTab>
            ))}
          </StyledTabList>
        </TabListContainer>
      </TabListBackgroundWrapper>

      <TabPanelContainer headerHeight={headerHeight}>
        {map(tabs, ({ content, loading, url }, index) => {
          return (
            <StyledTabPanel key={index}>
              <Route
                exact
                path={buildUrl(baseUrl, url)}
                render={() => (
                  <>
                    {loading ? (
                      <LoadingBarsContainer>
                        <LoadingBars color={COLORS.azure} />
                      </LoadingBarsContainer>
                    ) : (
                      <>
                        <TabContentContainer>{content}</TabContentContainer>
                      </>
                    )}
                  </>
                )}
              />
            </StyledTabPanel>
          );
        })}
      </TabPanelContainer>
    </TabsContainer>
  );
};

TabGroup.propTypes = {
  /** Takes an array of tabs with the following fields: {
   * title: string,
   * content: component,
   * footer: component (optional)
   * } */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
    }),
  ).isRequired,
};

const TabsContainer = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  box-shadow: 0 2px 4px 0 ${hexToRgba(COLORS.grey, 0.9)};
`;

const TabListBackgroundWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.white};
`;

const TabListContainer = styled.div`
  margin: 0 auto;
`;

const StyledTabList = styled(TabList)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const StyledTab = styled(Tab)`
  &.multi-tab:hover {
    ${({ disabled }) => (disabled ? '' : 'opacity: 0.8;')}
  }

  border-bottom: 3px solid ${COLORS.grey};

  &.react-tabs__tab--selected {
    border-bottom: 3px solid ${COLORS.azure};

    div {
      color: ${COLORS.azure};
    }
  }

  list-style: none;
  width: 50%;
  padding: 16px 16px 12px;
  text-align: center;

  &.multi-tab {
    ${({ disabled }) => (disabled ? '' : 'cursor: pointer;')}
  }
`;

const TabPanelContainer = styled.section`
  flex: 1;
`;

const StyledTabPanel = styled(TabPanel)`
  display: flex;
  flex-direction: column;
  &.react-tabs__tab-panel--selected {
    height: 100%;
  }
  position: relative;
  background-color: ${COLORS.white};
`;

const TabContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 32px;
  @media (max-width: ${MobileSize}) {
    padding-bottom: 8px;
  }
`;

const LoadingBarsContainer = styled.div`
  margin: 240px auto;
`;

export default withRouter(TabGroup);
