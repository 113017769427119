import { SET_ELIGIBILITY_BENEFITS, SET_SLC_USER } from 'constants/actionTypes';

const initialState = {
  slcUser: {},
  benefitDetails: null,
};

const slcReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ELIGIBILITY_BENEFITS: {
      return { ...state, benefitDetails: payload.studentLoanContribution };
    }

    case SET_SLC_USER: {
      return { ...state, slcUser: payload };
    }

    default:
      return state;
  }
};

export default slcReducer;
