import { push as redirectTo } from 'connected-react-router';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@summer/ui-components';

import { EDU_ASSIST_ADD_PROOF_OF_COMPLETION_BUTTON_CLICKED } from 'constants/trackingEvents';
import { trackServerSide } from 'redux/actions/analytics.actions';
import { setFileCollections } from 'redux/actions/fileCollections.actions';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';

// TODO: [sc-41260] add proof_rejected option here
const buttonDetails = {
  course_approved: {
    assistance: 'Add Proof of Completion',
    reimbursement: 'File Reimbursement',
    width: 190,
  },
  proof_rejected: {
    assistance: 'Resubmit',
    reimbursement: 'Resubmit',
    width: 130,
  },
};

const OpenProofWizardButton = ({ uuid, status, attachments }) => {
  const dispatch = useDispatch();
  const serviceType = useSelector(getTuitionReimbursementServiceType);

  const {
    tuitionReimbursementPaymentProof,
    tuitionReimbursementCompletionProof,
  } = attachments;

  const handleGoToProofOfCompletionWizard = useCallback(() => {
    dispatch(
      trackServerSide(EDU_ASSIST_ADD_PROOF_OF_COMPLETION_BUTTON_CLICKED, {
        integrations: { All: true },
      }),
    );
    dispatch(
      setFileCollections({
        fileCollections: {
          tuitionReimbursementCompletionProof,
          tuitionReimbursementPaymentProof,
        },
      }),
    );
    dispatch(
      redirectTo(`/educational-assistance/proof-of-completion`, {
        courseId: uuid,
      }),
    );
  }, [
    dispatch,
    tuitionReimbursementCompletionProof,
    tuitionReimbursementPaymentProof,
    uuid,
  ]);

  return (
    <Button
      textSize="14px"
      width={buttonDetails[status].width}
      onClick={handleGoToProofOfCompletionWizard}
    >
      {buttonDetails[status][serviceType]}
    </Button>
  );
};

export default OpenProofWizardButton;
