import { format, parseISO } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  Checkmark,
  COLORS,
  FileIcon,
  Icon,
  Text,
  WarningIcon,
} from '@summer/ui-components/src';

import { MobileSize } from 'constants/styleguide';
import {
  TuitionReimbursementServiceTypes,
  TuitionReimbursementStatuses,
} from 'constants/tuitionReimbursement';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';
import { hexToRgba } from 'utils/common';

/**
 * @typedef {'proof_submitted' | 'proof_approved'| 'proof_rejected'| 'course_requested'| 'course_approved'| 'course_rejected'} ApplicationStatus
 * @typedef {React.ComponentProps<typeof Icon>} BadgeIconProperties
 * @typedef {{ svgComponent: BadgeIconProperties['SvgComponent']; strokeColor: BadgeIconProperties['stroke']; iconWidth: BadgeIconProperties['width'] }} IconProperties
 * @typedef {{ assistanceDisplayText: string; reimbursementDisplayText: string; backgroundColor: string; }} DisplayNameProperties
 */

/**
 * Map of the reimbursement state from reimbursement responses to appropriate display values.
 * @type {Record<ApplicationStatus, IconProperties & DisplayNameProperties>}
 */
export const statusDisplayNames = {
  proof_submitted: {
    assistanceDisplayText: 'Proof of completion submitted. Awaiting review.',
    reimbursementDisplayText: 'Reimbursement Filed. Waiting for payment.',
    svgComponent: FileIcon,
    strokeColor: COLORS.darkerGrey,
    backgroundColor: COLORS.lighterGrey,
    iconWidth: 16,
  },
  proof_approved: {
    assistanceDisplayText: 'Proof of Payment and Completion Approved',
    reimbursementDisplayText: 'Reimbursement Completed',
    svgComponent: Checkmark,
    strokeColor: COLORS.darkGreen,
    backgroundColor: hexToRgba(COLORS.jade, 0.12),
    iconWidth: 18,
  },
  proof_rejected: {
    assistanceDisplayText: 'Proof Rejected',
    reimbursementDisplayText: 'Reimbursement Rejected',
    svgComponent: WarningIcon,
    strokeColor: COLORS.orange,
    backgroundColor: hexToRgba(COLORS.orange, 0.08),
    iconWidth: 20,
  },
  course_requested: {
    assistanceDisplayText: 'Request Submitted',
    reimbursementDisplayText: 'Request Submitted',
    svgComponent: FileIcon,
    strokeColor: COLORS.darkerGrey,
    backgroundColor: COLORS.lighterGrey,
    iconWidth: 16,
  },
  course_approved: {
    assistanceDisplayText: 'Course Approved',
    reimbursementDisplayText: 'Course Approved',
    svgComponent: Checkmark,
    strokeColor: COLORS.darkGreen,
    backgroundColor: hexToRgba(COLORS.jade, 0.12),
    iconWidth: 18,
  },
  course_rejected: {
    assistanceDisplayText: 'Course Rejected',
    reimbursementDisplayText: 'Course Rejected',
    svgComponent: WarningIcon,
    strokeColor: COLORS.orange,
    backgroundColor: hexToRgba(COLORS.orange, 0.08),
    iconWidth: 20,
  },
};

/** @typedef {{ status: ApplicationStatus; createdAt: string }} Props */

/**
 * @param {Props} props
 * @returns {React.ReactNode}
 */
const DisplayText = ({ status, createdAt }) => {
  const serviceType = useSelector(getTuitionReimbursementServiceType);
  if (status === TuitionReimbursementStatuses.COURSE_REQUESTED) {
    const courseRequestedAt = format(parseISO(createdAt), 'MMMM dd, yyyy');

    return `Request Submitted ${courseRequestedAt}. Waiting for approval.`;
  }

  const shouldShowReimbursementDisplayText =
    serviceType === TuitionReimbursementServiceTypes.REIMBURSEMENT;

  const { reimbursementDisplayText, assistanceDisplayText } =
    statusDisplayNames[status];

  return shouldShowReimbursementDisplayText
    ? reimbursementDisplayText
    : assistanceDisplayText;
};

/**
 * @param {Props} props
 * @returns {React.ReactNode}
 */
const TuitionReimbursementStatus = ({ status, createdAt }) => {
  const { backgroundColor, iconWidth, svgComponent, strokeColor } =
    statusDisplayNames[status];

  return (
    <Badge backgroundColor={backgroundColor}>
      <BadgeIcon
        width={iconWidth}
        SvgComponent={svgComponent}
        stroke={strokeColor}
      />
      <Text size="extraSmall" bold>
        <DisplayText status={status} createdAt={createdAt} />
      </Text>
    </Badge>
  );
};

const BadgeIcon = styled(Icon)`
  margin-right: 8px;
`;

const Badge = styled.div`
  max-width: 280px;
  border-radius: 3px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: 0.9;
  padding: 6px 12px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
  }
`;

export default TuitionReimbursementStatus;
